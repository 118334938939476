// AccountSection.js
import React, { useState, useEffect, useCallback } from 'react';

function AccountSection({ isAuthenticated, onLogout }) {
  const [accountData, setAccountData] = useState([]);
  const [schedulerStatus, setSchedulerStatus] = useState('stop');
  const [schedulerStatusOneAccount, setSchedulerStatusOneAccount] = useState(() => initializeSchedulerStatus());
  const [newAccountEmail, setNewAccountEmail] = useState('');
  const [newAccountKey, setNewAccountKey] = useState('');
  const [accountHistories, setAccountHistories] = useState([]);
  //const [isAppRunning, setIsAppRunning] = useState(false);  
  const [accountEmail, setAccountEmail] = useState('');  // New state for scheduler status
  const [lastCheckedTimestamps, setLastCheckedTimestamps] = useState({});
  const [withdrawAmounts, setWithdrawAmounts] = useState({});

/// Lazy initializer function to load state from local storage if it exists
function initializeSchedulerStatus() {
  const storedSchedulerStatus = localStorage.getItem('schedulerStatusOneAccount');
  return storedSchedulerStatus ? JSON.parse(storedSchedulerStatus) : {};
}

const checkSchedulerStatus = () => {
  fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/scheduler-status`)
    .then(response => response.json())
    .then(data => {
      setSchedulerStatus(data.status === 'running' ? 'start' : 'stop');
    })
    .catch(error => {
      console.error('Error checking scheduler status:', error);
    });
};

const checkSchedulerStatusForOneAcc = (accountEmail) => {
  fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/scheduler-status-for-one-account`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ accountEmail }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok (status ${response.status})`);
      }
      return response.json();
    })
    .then((data) => {
      setSchedulerStatusOneAccount((prevStatus) => ({
        ...prevStatus,
        [accountEmail]: data.status === 'running' ? 'start' : 'stop',
      }));
    })
    .catch((error) => {
      console.error('Error checking scheduler status:', error);
    });
};

useEffect(() => {
  if (Object.keys(schedulerStatusOneAccount).length > 0) {
    localStorage.setItem('schedulerStatusOneAccount', JSON.stringify(schedulerStatusOneAccount));
    console.log('Saved scheduler status to local storage:', schedulerStatusOneAccount);
  }
}, [schedulerStatusOneAccount]);


  useEffect(() => {
    fetchAccountData();
    //fetchAccountHistories();
    const intervalId = setInterval(fetchAccountData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  function AppStatus() {
    const [isAppRunning, setIsAppRunning] = useState(false);

    useEffect(() => {
      const checkAppStatus = async () => {
        console.log('app status');
        try {
          const response = await fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/ping`);
          if (response.ok) {
            setIsAppRunning(true);
          } else {
            setIsAppRunning(false);
          }
        } catch (error) {
          setIsAppRunning(false);
        }
      };

      const statusCheckInterval = setInterval(checkAppStatus, 300000);

      checkAppStatus();

      return () => clearInterval(statusCheckInterval);
    }, []);

    return (
      <div className={`alert ${isAppRunning ? 'alert-success' : 'alert-danger'}`}>
        {isAppRunning ? (
          <p>The application is healthy.</p>
        ) : (
          <p>The application is not healthy.</p>
        )}
      </div>
    );
  }

  const fetchAccountData = () => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/accounts/accounts`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          setAccountData(data);

          // // Initialize schedulerStatusOneAccount for each account (default to 'stop')
          // const schedulerStatuses = {};
          // data.forEach((account) => {
          //   schedulerStatuses[account.email] = 'stop'; // Set initial value to 'stop'
          // });
          // setSchedulerStatusOneAccount(schedulerStatuses);

          // Update the lastCheckedTimestamps state for each account
          const updatedTimestamps = {};
          data.forEach((account) => {
            // Use the account email as the key to store the last checked timestamp
            updatedTimestamps[account.email] = new Date().toLocaleString();
          });
          setLastCheckedTimestamps(updatedTimestamps);
        } else {
          console.log('Error');
        }
      })
      .catch((error) => console.log('Error:', error));
  };

  const fetchAccountHistories = async () => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/get-accounts-histories`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {

        if (data && data.combinedHistory !== null && Array.isArray(data.combinedHistory)) {
          // Check if combinedHistory is not null and is an array          
          setAccountHistories(data.combinedHistory);
          //console.log('inside if 2: ' + accountHistories);
        } else {
          console.log('combinedHistory is null or not an array');
          // Handle the case where combinedHistory is null or not an array
          // You can set accountHistories to an empty array or display an error message
          setAccountHistories([]);
        }
      })
      .catch((error) => console.log('Error fetching account histories:', error));
  };

  const handleButtonClick = (account) => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/trigger-scheduler-for-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail: account.email }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        console.log('Response data:', data);
        if (data.message === 'Success') {
          // Check if the response indicates success
          console.log('Request was successful for account:', account.email);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === account.email) {
              return { ...acc, success: true, failed: false };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        } else {
          // Check if the response indicates failure
          console.log('Request failed for account:', account.email);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === account.email) {
              return { ...acc, success: false, failed: true };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        const updatedAccountData = accountData.map((acc) => {
          if (acc.email === account.email) {
            return { ...acc, success: false, failed: true };
          }
          return acc;
        });
        setAccountData(updatedAccountData);
      });
  };

  const withdrawSpecifiedUSDTButton = (accountEmail, specifiedUSDTAmount) => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/withdraw-specified-usdt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail, usdtAmount: specifiedUSDTAmount }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        console.log('Response data:', data);
        if (data.message === 'Success') {
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: true, failed: false };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        } else {
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: false, failed: true };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        const updatedAccountData = accountData.map((acc) => {
          if (acc.email === accountEmail) {
            return { ...acc, success: false, failed: true };
          }
          return acc;
        });
        setAccountData(updatedAccountData);
      });
  };
  

  const withdrawAllUSDTButton = (accountEmail) => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/withdraw-all-usdt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail: accountEmail }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        console.log('Response data:', data);
        if (data.message === 'Success') {
          // Check if the response indicates success
          console.log('Request was successful for account:', accountEmail);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: true, failed: false };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        } else {
          // Check if the response indicates failure
          console.log('Request failed for account:', accountEmail);
          const updatedAccountData = accountData.map((acc) => {
            if (acc.email === accountEmail) {
              return { ...acc, success: false, failed: true };
            }
            return acc;
          });
          setAccountData(updatedAccountData);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        const updatedAccountData = accountData.map((acc) => {
          if (acc.email === accountEmail) {
            return { ...acc, success: false, failed: true };
          }
          return acc;
        });
        setAccountData(updatedAccountData);
      });
  };

  const toggleSchedulerForOneAccount = (accountEmail) => {
    const currentStatus = schedulerStatusOneAccount[accountEmail];

    const newStatus = currentStatus === 'stop' ? 'start' : 'stop';


    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/${newStatus}-scheduler-for-one-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok (status ${response.status})`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Received data:', data);
        // Update the scheduler status for the account
        setSchedulerStatusOneAccount((prevStatus) => ({
          ...prevStatus,
          [accountEmail]: newStatus,
        }));
      })
      .catch((error) => {
        console.error('Error starting/stopping scheduler:', error);
      });
  };


  const toggleScheduler = () => {
    const newStatus = schedulerStatus === 'stop' ? 'start' : 'stop';

    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/${newStatus}-scheduler`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok (status ${response.status})`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Received data:', data);
        setSchedulerStatus(newStatus);
      })
      .catch((error) => {
        console.error('Error starting scheduler:', error);
      });
  };

  const handleAddAccount = () => {
    const cleanedEmail = newAccountEmail.trim();

    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/insert-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountEmail: cleanedEmail, key: newAccountKey }),
    })
      .then((response) => response.json())
      .then((data) => {
        fetchAccountData();
        setNewAccountEmail('');
        setNewAccountKey('');
      })
      .catch((error) => {
        console.error('Error adding account:', error);
      });
  };

  const handleDeleteAccount = (accountEmail) => {
    fetch(`http://${process.env.REACT_APP_API_URL}/api/v1/delete-account/${accountEmail}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        fetchAccountData();
      })
      .catch((error) => {
        console.error('Error deleting account:', error);
      });
  };

  // Render account history rows
  // const renderHistoryRows = () => {
  //   if (accountHistories && accountHistories.length > 0) {
  //     return accountHistories.map((combinedHistory, index) => {
  //       if (combinedHistory === null) {
  //         return null;
  //       }

  //       return combinedHistory.history.map((historyItem, historyIndex) => (
  //         <tr key={historyIndex}>
  //           <td>{combinedHistory.email}</td>
  //           <td>{historyItem.amount}</td>
  //           <td>{historyItem.updated_at}</td>
  //           <td>{historyItem.state}</td>
  //         </tr>
  //       ));
  //     });
  //   } else {
  //     return (
  //       <tr>
  //         <td colSpan="5">No account history data found.</td>
  //       </tr>
  //     );
  //   }
  // };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Accounts</h1>
      <AppStatus />
      {isAuthenticated && (
        <>
          <div className="mt-3">
            <button className="btn btn-info" onClick={toggleScheduler}>
              {schedulerStatus === 'stop' ? 'Start Scheduler' : 'Stop Scheduler'}
            </button>
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="New Account Email"
              value={newAccountEmail}
              onChange={(e) => setNewAccountEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="New Account Key"
              value={newAccountKey}
              onChange={(e) => setNewAccountKey(e.target.value)}
            />
          </div>
          <div>
            <button className="btn btn-primary" onClick={handleAddAccount}>
              Add Account
            </button>
          </div>
        </>
      )}

      <h2 className="mt-4">Account Data</h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Email</th>
            <th>ZAR SN</th>
            <th>Balance (ZAR)</th>
            <th>Balance (USDT)</th>
            <th>Total Withdrawn(USDT)</th>
            <th>Last checked time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {accountData.length === 0 ? (
            <tr>
              <td colSpan="4">No account data found in the response.</td>
            </tr>
          ) : (
            accountData.map((account, index) => (
              <tr key={index}>
                <td>{account.email}</td>
                <td>{account.zarZN}</td>
                <td>{account.zarBalance}</td>
                <td>{account.usdtBalance}</td>
                <td>{account.totalUSDTWithdrawals}</td>
                <td>{lastCheckedTimestamps[account.email] || 'N/A'}</td>
                <td>
                  {schedulerStatusOneAccount[account.email] !== undefined ? (
                    <button
                      className="btn btn-info"
                      onClick={() => toggleSchedulerForOneAccount(account.email)}
                    >
                      {schedulerStatusOneAccount[account.email] === 'stop' ? 'Start Scheduler' : 'Stop Scheduler'}
                    </button>
                  ) : (
                    <span>Loading...</span> // Or you could show a spinner
                  )}
                  <button
                    className="btn btn-success"
                    onClick={() => handleButtonClick(account)}
                  >
                    Trade
                  </button>
                  <input
        type="number"
        className="form-control"
        placeholder="Amount (USDT)"
        value={withdrawAmounts[account.email] || ''}  // Value from the state
        onChange={(e) =>
          setWithdrawAmounts({ ...withdrawAmounts, [account.email]: e.target.value })
        }
      />

      {/* Withdraw Button */}
      <button
        className="btn btn-success"
        onClick={() => withdrawSpecifiedUSDTButton(account.email, withdrawAmounts[account.email])}
        disabled={!withdrawAmounts[account.email]}  // Disable if no amount is entered
      >
        Withdraw USDT S
      </button>
                  <button
                    className="btn btn-success"
                    onClick={() => withdrawAllUSDTButton(account.email)}
                  >
                    Withdraw USDT
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteAccount(account.email)}
                  >
                    Delete
                  </button>
                  {account.success && (
                    <span className="text-success">Successful</span>
                  )}
                  {account.failed && <span className="text-danger">Failed</span>}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <h2 className="mt-4">Account History</h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Email</th>
            <th>Amount (USDT)</th>
            <th>Updated At</th>
            <th>State</th>
          </tr>
        </thead>
        {/* <tbody>{renderHistoryRows()}</tbody> */}
      </table>
    </div>
  );
}

export default AccountSection;
